export const GET_AGENT_PLAYERS = 'GET_AGENT_PLAYERS'

export const GET_AGENT_PLAYERS_SUCCESS = 'GET_AGENT_PLAYERS_SUCCESS'

export const GET_AGENT_PLAYERS_FAILED = 'GET_AGENT_PLAYERS_FAILED'

export const SET_AGENT_PLAYERS = 'SET_AGENT_PLAYERS'

export const PLACE_BET = 'PLACE_BET'

export const PLACE_BET_SUCCESS = 'PLACE_BET_SUCCESS'

export const ADD_PLAYER = 'ADD_PLAYER'

export const REMOVE_PLAYER = 'REMOVE_PLAYER'

//CREATE USER

export const CREATE_AGENT_PLAYERS = 'CREATE_AGENT_PLAYERS'

export const CREATE_AGENT_PLAYERS_SUCCESS = 'CREATE_AGENT_PLAYERS_SUCCESS'

export const CREATE_AGENT_PLAYERS_FAILED = 'CREATE_AGENT_PLAYERS_FAILED'
