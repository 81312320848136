import {
    GET_AGENT_PLAYERS,
    GET_AGENT_PLAYERS_SUCCESS,
    GET_AGENT_PLAYERS_FAILED,
    PLACE_BET,
    PLACE_BET_SUCCESS,
    ADD_PLAYER,
    REMOVE_PLAYER,
    CREATE_AGENT_PLAYERS,
    CREATE_AGENT_PLAYERS_SUCCESS,
    CREATE_AGENT_PLAYERS_FAILED,
} from './BetFieldTypes'


// START GETTING AGENT PLAYERS
export const getAgentPlayers = () => {
    return {
        type: GET_AGENT_PLAYERS
    }
}

export const getAgenPlayersSuccess = (payload) => {
    return {
        type: GET_AGENT_PLAYERS_SUCCESS,
        payload
    }
}

export const getAgentPlayersFailed = (payload) => {
    return {
        type: GET_AGENT_PLAYERS_FAILED,
        payload
    }
}
// END GETTING AGENT PLAYERS

// PLACE BET
export const placeBet = () => {
    return {
        type: PLACE_BET
    }
}

export const placeBetSuccess = (payload) => {
    return {
        type: PLACE_BET_SUCCESS,
        payload
    }
}

// END PLACE BET


// START AGENT PLAYER INTERACTION
export const addPlayer = (betType) => {
    return {
        type: ADD_PLAYER,
        betType
    }
}

export const removePlayer = () => {
    return {
        type: REMOVE_PLAYER
    }
}

// END AGENT PLAYER INTERACTION



export const selectPlayer = (payload) => {
    return {
        type: 'PLAYER_SELECT',
        players: payload
    }
}

export const setAgentPlayers = (players) => {
    return {
        type: 'SET_AGENT_PLAYERS',
        players
    }
}


//START AGENT PLAYER INTERACTION

export const createAgentPlayers = (player) => {
    return {
        type: CREATE_AGENT_PLAYERS,
        payload: player
    }
}

export const createAgenPlayersSuccess = (payload) => {
    return {
        type: CREATE_AGENT_PLAYERS_SUCCESS,
        payload
    }
}

export const createAgentPlayersFailed = (payload) => {
    return {
        type: CREATE_AGENT_PLAYERS_FAILED,
        payload
    }
}