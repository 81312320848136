import * as types from './BetFieldTypes'

const initialState = {
    loading: false,
    data: [],
    errors: null
}

export const getAgentPlayers = (state = initialState, action) => {
    switch(action.type) {
        case types.GET_AGENT_PLAYERS:
            return {
                ...state,
                loading: true
            }
        case types.GET_AGENT_PLAYERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case types.GET_AGENT_PLAYERS_FAILED:
            return {
                ...state,
                loading: false,
                errors: action.payload
            }
        default:
            return state
    }
}

export const placeBet = (state = initialState, action) => {
    switch(action.type) {
        case types.PLACE_BET:
            return {
                ...state,
                loading: true
            }
        case types.PLACE_BET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        default:
            return state = initialState
    }
}


export const playerInteraction = (state = initialState, action) => {
    switch(action.type) {
        case types.ADD_PLAYER:
            return {
                ...state,
                loading: true,
                data: action.type
            }
        case types.REMOVE_PLAYER:
            return {
                ...state,
                loading: true,
                data: action.type
            }
        default:
            return state = initialState
    }
}


// export default (state = 0, action) => {
//     switch(action.type) {
//         case 'ADD_PLAYER':
//             return {
//                 action
//             }
//         case 'PLAYER_SELECT':
//             return {
//                 action
//             }
            
//         case 'PLACE_BET_MERON':
//             return {
//                 action
//             }
//         case 'PLACE_BET_WALA':
//             return {
//                 action
//             }
//         case 'PLACE_BET_DRAW':
//             return {
//                 action
//             }
//         default:
//             return state
//     }
// }