import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import sagas from './sagas'
import rootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()

const ConfigSaga = (initialState) => {
    const enhancers = [
        applyMiddleware(sagaMiddleware, logger)
    ]

    const persistConfig = {
        key: 'mebetPersist',
        storage: storage,
        whitelist: ['play','auth', 'getWalletUpdate', 'getAgentPlayers'] // which reducer want to store
    }

    const pReducer = persistReducer(persistConfig, rootReducer)

    const store = createStore(
        pReducer,
        initialState,
        compose(...enhancers),
    )
    const persistor = persistStore(store)

    sagaMiddleware.run(sagas)

    return { persistor, store }
}

const store = ConfigSaga() 

export default store
