import { combineReducers } from 'redux'
import auth from './Auth/AuthReducer'
import play from './Play/PlayReducer'
import { betTypeButtonSelect, betTypeDisableButton } from './BetTypes/BetTypeReducer'
import { getAgentPlayers, playerInteraction } from './BetFields/BetFieldReducers'
import { getBettingHistory } from './BettingHistory/BettingHistoryReducers'
import { getWalletUpdate } from './AppBar/HeaderReducer'

export default combineReducers({
    auth,
    play,
    betTypeButtonSelect,
    betTypeDisableButton,
    getWalletUpdate,
    getAgentPlayers,
    getBettingHistory,
    playerInteraction
})

